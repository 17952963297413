import {
    SET_LOCALE,
    UPDATE_CONNECTION_STATUS,
    LIST_USER_NOTIFICATIONS,
    SET_NOTIFICATION_STATUS,
    PREPEND_NEW_NOTIFICATION,
    SET_NOTIFICATIONS_COUNTER,
    LIST_USER_TRANSACTIONS,
    APPLY_USER_TRANSACTION
} from './action-types'

export function setLocale(payload){
    return {
        type: SET_LOCALE,
        payload,
    }
}

export function updateConnectionStatus(payload){
    return {
        type: UPDATE_CONNECTION_STATUS,
        payload,
    }
}

export function listUserNotifications(payload){
    return {
        type: LIST_USER_NOTIFICATIONS,
        payload
    }
}

export function setNotificationStatus(payload){
    return {
        type: SET_NOTIFICATION_STATUS,
        payload
    }
}

export function prependNewNotification(payload) {
    return {
        type: PREPEND_NEW_NOTIFICATION,
    }
}

export function listUserTransactions(payload) {
    return {
        type: LIST_USER_TRANSACTIONS,
        payload
    }
}

export function setNotificationsCounter(payload) {
    return {
        type: SET_NOTIFICATIONS_COUNTER,
    }
}

export function applyUserTransaction(payload) {
    return {
        type: APPLY_USER_TRANSACTION,
        payload
    }
}