import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { useLocation } from "react-router-dom"
import routes from './routes';
// import { notification } from "antd";
// import {messaging} from '../resources/firebase.js' 
import {createBrowserHistory} from 'history'
import {connect} from 'react-redux'
import ReactGA from "react-ga";
import Error404 from '../admin/modules/Errors/components/Error404/Error404';
// import { setDeviceToken } from '../admin/modules/Common/service';
import store from '../store';
// import { prependNewNotification } from '../admin/modules/Common/store/actions';
// import moment from 'moment';
import notificationSound from '../resources/notification-sound.mp3'

const history = createBrowserHistory()
var _fcmToken = localStorage.getItem('_ft')
var _nc = localStorage.getItem('_nc')
const audio = document.createElement('audio')
audio.src = notificationSound

history.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
})

// /**
//  * Get user device token
//  * @param {*} granted 
//  */
// async function getDeviceToken(granted, isAuthenticated){
// 	try {
// 		if(messaging){
// 			messaging.requestPermission().then(async function() {
// 				const token = await messaging.getToken();
// 				if(!_fcmToken && isAuthenticated){
// 					setUserDeviceToken(token)
// 				}
// 				granted(token)
// 			}).catch(function(err) {
// 				console.log("Unable to get permission to notify.", err);
// 			});
// 		}
// 		else {
// 			console.log('Your browser does not support push notifications')
// 		}
// 	} 
// 	catch (error) {
// 		console.log(error)
// 	}
// }

// function setUserDeviceToken(token){
// 	setDeviceToken(token, () => {
// 		localStorage.setItem('_ft', token)
// 	}, () => {

// 	})
// }

function _handleNotificationAction(notification, cardRequest, contactUsRequest){
	switch (notification.notificationType) {
		case 1:
			// this.fetchRequestDetails(notification.relatedItem)
			break;
		case 2: 
			cardRequest(notification.relatedItem)
			break;
		case 5: 
			contactUsRequest(notification.relatedItem)
			break;
		default:
			break;
	}
}

/**
 * Detects if user is idle within a specific amout of ms
 * @param {*} ms 
 * @param {*} cb 
 */
function detectIdle(ms, cb) {
    var wait = setTimeout(cb, ms);
    document.onfocus = document.onmousedown = document.onmouseenter = document.onmouseleave = document.onkeydown = document.onkeypress = document.onkeyup = function (e) {
		clearTimeout(wait);
        wait = setTimeout(cb, ms);
	};
	document.addEventListener("visibilitychange", function() {
		if(document.hidden){
			clearTimeout(wait);
		}
	})
}

/**
 * Returns a public component
 * @param {Object} props 
 */

const PublicRoute = ({ isAuthenticated, component: Component, ...rest, }) => {
	const { pathname } = useLocation();
	useEffect(() => {
		document.documentElement.scrollTop = 0
		// getDeviceToken((token) => {
		// 	messaging.onMessage((data) => {
		// 		let notificationInfo = data.notification
		// 		let notificationData = data.data
		// 		let incomingDate = moment(notificationData.CreatedDate).format('dddd DD MMMM, YYYY')
		// 		localStorage.setItem('_nc', parseInt(_nc) + 1)
		// 		store.dispatch(prependNewNotification({notificationInfo, notificationData, incomingDate}))
		// 		audio.play()
		// 		notification.info({
		// 			message: notificationInfo.title,
		// 			description: notificationInfo.body,
		// 			placement: 'bottomRight',
		// 			onClick: () => {
		// 				_handleNotificationAction(notificationData, (id) => {
		// 					console.log(id)
		// 				}, (id) => {
		// 					console.log(id)
		// 				})
		// 			}
		// 		})
		// 	})
		// }, isAuthenticated)
		ReactGA.pageview(window.location.pathname)
	}, [pathname])
	return (<Route {...rest} render={props => (<Component {...props} allowedType={rest.allowedType}/>)} />)		

}

/**
 * Returns a private component if user is authenticated and have sufficient permissions
 * @param {Object} props 
 */
const PrivateRoute = ({ component: Component, isAuthenticated, roles, ...rest }) => {
	const [isIdle, setIdleState] = useState(false);
	const { pathname } = useLocation();
	useEffect(() => {
		document.addEventListener("visibilitychange", function() {
			if (!document.hidden){
				detectIdle(1800000, function () {
					setIdleState(true)
				})
			}
		})
		document.body.scrollTop = 0
		// getDeviceToken((token) => {
		// 	messaging.onMessage((data) => {
		// 		let notificationInfo = data.notification
		// 		let notificationData = data.data
		// 		let incomingDate = moment(notificationData.CreatedDate).format('dddd DD MMMM, YYYY')
		// 		localStorage.setItem('_nc', parseInt(_nc) + 1)
		// 		store.dispatch(prependNewNotification({notificationInfo, notificationData, incomingDate}))
		// 		audio.play()
		// 		notification.info({
		// 			message: notificationInfo.title,
		// 			description: notificationInfo.body,
		// 			placement: 'bottomRight',
		// 			onClick: () => {
		// 				_handleNotificationAction(notificationData, (id) => {
		// 					console.log(id)
		// 				}, (id) => {
		// 					console.log(id)
		// 				})
		// 			}
		// 		})
		// 	})
		// }, isAuthenticated)
		ReactGA.pageview(window.location.pathname)
	}, [pathname])

	const userType = localStorage.getItem('_ut') ? localStorage.getItem('_ut') : sessionStorage.getItem('_ut')

	return (
		<Route {...rest} render={props => (
			!isIdle ? (isAuthenticated
				? (
					(roles ? rest.allowedRoles.every(role => roles.indexOf(role) > -1) : true) ? 
					<Component allowedType={rest.allowedType} nestedRoutes={rest.nestedRoutes} path={rest.path} {...props} /> : <Redirect to={{
						pathname: '/error401',
						state: { from: props.location }
					}}/>
				)
				: <Redirect to={{
					pathname: userType === '1' || rest.location.pathname.includes('admin') ? '/signin' : '/SignInUp',
					state: { from: props.location },
				}} />) : <Redirect to={{
					pathname: '/timed-out',
					state: { from:  props.location}
				}}/>
		)} />
		
	)
}

const Routes = ({isAuthenticated, roles}) => {
	return (
	<Router hisotry={history}>
		<Switch>
			{routes.map((route, i) => {
			if(route.isAuth){
				return (
				<PrivateRoute roles={roles} isAuthenticated={isAuthenticated} key={i} {...route} />
				)
			}
			return( <PublicRoute isAuthenticated={isAuthenticated} roles={roles} key={i} {...route} />)
			})}
			<Route component={Error404}/>
		</Switch>
	</Router>
)}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    roles: state.auth.userRoles
  }
}

export default connect(mapStateToProps)(Routes)
