import {
    SET_LOCALE, UPDATE_CONNECTION_STATUS, LIST_USER_NOTIFICATIONS, SET_NOTIFICATION_STATUS, LIST_USER_TRANSACTIONS, APPLY_USER_TRANSACTION, PREPEND_NEW_NOTIFICATION, SET_NOTIFICATIONS_COUNTER
} from './action-types'
import { en } from '../../../../resources/en';
import { ar } from '../../../../resources/ar';
import moment from 'moment';


/**
 * Module internal initial state
 */
const initialState = {
    currentLocale: null,
    currentResource: null,
    connectionStatus: false,
    currentDirection: null,
    notificationsList: [],
    transactionsList: [],
    transactionsTotalCount: null,
    customerBalance: null,
};  


/**
 * Check dispatched action and update state accordingly
 * @param {Object} state 
 * @param {Object} action 
 * @returns {Function} 
 */

const reducer = (state = initialState, {type, payload = null}) => {
    switch (type) {
        case SET_LOCALE: 
            return setLocale(state, payload)
        case UPDATE_CONNECTION_STATUS: 
            return updateConnectionStatus(state, payload)
        case LIST_USER_NOTIFICATIONS:
            return setNotificationsList(state, payload)
        case SET_NOTIFICATION_STATUS:
            return setNotificationStatus(state, payload)
        case PREPEND_NEW_NOTIFICATION:
            return addNewNotification(state, payload)
        case SET_NOTIFICATIONS_COUNTER:
            return setNotificationsCounter(state, payload)
        case LIST_USER_TRANSACTIONS: 
            return setCustomerTransactionsInfo(state, payload)
        case APPLY_USER_TRANSACTION: 
            return applyCustomerTransaction(state, payload)
        default:
            return state;
    }
} 

function setLocale(state, payload){
    localStorage.setItem('currentLocale', payload)
    return {
        ...state, currentLocale: payload, currentResource: payload === 'english' ? en : ar, currentDirection: payload === 'en' ? 'ltr' : 'rtl'
    }
}

function updateConnectionStatus(state, payload){
    return {
        ...state, connectionStatus: payload
    }
}

function setNotificationsList(state, payload) {
    return {
        ...state, notificationsList: payload.list
    }
}

function setNotificationStatus(state, payload) {
    let index
    let notificationsList = state.notificationsList
    for (const prop in notificationsList) {
        if(prop === payload.key){
            index = notificationsList[prop].findIndex(notification => notification.notificationID === payload.id)
            if(index > -1){
                notificationsList[prop][index].isSeen = true
            }
        }
    }
    return {
        ...state, notificationsList, notificationsCount: state.notificationsCount - 1
    }
}

function addNewNotification(state, payload){
    let notificationsList = state.notificationsList
    for (const date in notificationsList) {
        if(payload.incomingDate === date){
            // console.log(date)
        }
    }

    return {
        ...state, notificationsCount: state.notificationsCount + 1
    }
}

function setNotificationsCounter(state, payload) {
    return {
        ...state, notificationsCount: payload
    }  
} 

function setCustomerTransactionsInfo(state, payload) {
    return {
        ...state, transactionsList: payload.transactionsDates, transactionsTotalCount: payload.totalCount,
        customerBalance: payload.balance
    }
}

function applyCustomerTransaction(state, payload) {
    let list = state.transactionsList
    let index = list.findIndex(item => moment(item.date).format('YYYY-MM-DD') === moment(payload.createdDate).format('YYYY-MM-DD'))
  //  console.log(index)
    if(index > -1){
        let transactions = list[index].transactions
        transactions = [payload, ...transactions]
        list[index].transactions = transactions
    }
    else {
        list.unshift({
            date: payload.createdDate,
            transactions: [payload]
        })
    }
    return {
        ...state, transactionsList: list, transactionsTotalCount: state.transactionsTotalCount + 1,
        customerBalance: state.customerBalance + payload.amount
    }
}

export default reducer