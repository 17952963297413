import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import registerServiceWorker from './registerServiceWorker';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga'

Sentry.init({dsn: "https://dcb242e642924851bb01176d6d058fb8@sentry.io/1488790"});

ReactGA.initialize('UA-187776526-1')


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// registerServiceWorker()
