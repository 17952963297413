export const LIST_ADMINS = 'LIST_ADMINS'
export const ADD_ADMIN = 'ADD_ADMIN'
export const DELETE_ADMIN = 'DELETE_ADMIN'
export const EDIT_ADMIN = 'EDIT_ADMIN'
export const LIST_ROLES = 'LIST_ROLES'
export const ADD_ROLE = 'ADD_ROLE'
export const ADD_DATA = 'ADD_DATA'
export const DELETE_ROLE = 'DELETE_ROLE'
export const LIST_MASTER = 'LIST_MASTER'
export const SET_MASTER_ACTIVE_TYPE = 'SET_MASTER_ACTIVE_TYPE'
export const DELETE_DATA = 'DELETE_DATA'
export const UPDATE_LOCATION = 'UPDATE_LOCATION'
export const LIST_STATIONS = 'LIST_STATIONS'
export const STATION_CU = 'STATION_CU'
export const LIST_CUSTOMERS = 'LIST_CUSTOMERS'
export const LIST_CARD_REQUESTS = 'LIST_CARD_REQUESTS'
export const LIST_COUNTRIES = 'LIST_COUNTRIES'
export const DELETE_COUNTRY = 'DELETE_COUNTRY'
export const LIST_GOVERNORATES = 'LIST_GOVERNORATES'
export const SET_ACTIVE_COUNTRY = 'SET_ACTIVE_COUNTRY'
export const ADD_GOVERNORATE = 'ADD_GOVERNORATE'
export const DELETE_GOVERNORATE = 'DELETE_GOVERNORATE'
export const SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS'
export const SET_CARD_DETAILS = 'SET_CARD_DETAILS'
export const UPDATE_CARD_STATUS = 'UPDATE_CARD_STATUS'
export const LIST_CONTACT_REQUESTS = 'LIST_CONTACT_REQUESTS'
export const UPDATE_CONTACT_STATUS = 'UPDATE_CONTACT_STATUS'
export const SET_DRAWER_ACTIVE_TAB = 'SET_DRAWER_ACTIVE_TAB'
export const INSER_CHARGER_DETAILS = 'INSER_CHARGER_DETAILS'
export const LIST_CHARGERS_DETAILS = 'LIST_CHARGERS_DETAILS'
export const APPEND_CONNECTORS_TO_CHARGER = 'APPEND_CONNECTORS_TO_CHARGER'
export const LIST_CHARGING_SESSIONS = 'LIST_CHARGING_SESSIONS'
export const EDIT_CONNECTORS_TO_CHARGER = 'EDIT_CONNECTORS_TO_CHARGER' 
export const DELETE_CHARGERS_FROM_LIST = 'DELETE_CHARGERS_FROM_LIST'
export const EDIT_CHARGER_DETAILS = 'EDIT_CHARGER_DETAILS'
export const DELETE_CHARGER_STATION_LIST = 'DELETE_CHARGER_STATION_LIST'
export const SET_CHARGER_DETAILS_LIST = 'SET_CHARGER_DETAILS_LIST'
export const RESET_CHARGER_DETAILS = 'RESET_CHARGER_DETAILS'


export default {
    LIST_ADMINS,
    ADD_ADMIN,
    DELETE_ADMIN,
    EDIT_ADMIN,
    LIST_ROLES,
    ADD_ROLE,
    DELETE_ROLE,
    LIST_MASTER,
    SET_MASTER_ACTIVE_TYPE,
    ADD_DATA,
    DELETE_DATA,
    UPDATE_LOCATION,
    LIST_STATIONS,
    STATION_CU,
    LIST_CUSTOMERS,
    LIST_CARD_REQUESTS,
    LIST_COUNTRIES,
    DELETE_COUNTRY,
    LIST_GOVERNORATES,
    SET_ACTIVE_COUNTRY,
    ADD_GOVERNORATE,
    DELETE_GOVERNORATE,
    SET_CUSTOMER_DETAILS,
    UPDATE_CARD_STATUS,
    LIST_CONTACT_REQUESTS,
    UPDATE_CONTACT_STATUS,
    SET_DRAWER_ACTIVE_TAB,
    INSER_CHARGER_DETAILS,
    LIST_CHARGERS_DETAILS,
    APPEND_CONNECTORS_TO_CHARGER,
    LIST_CHARGING_SESSIONS,
    EDIT_CONNECTORS_TO_CHARGER,
    DELETE_CHARGERS_FROM_LIST,
    EDIT_CHARGER_DETAILS,
    DELETE_CHARGER_STATION_LIST,
    SET_CHARGER_DETAILS_LIST,
    RESET_CHARGER_DETAILS
}