/**
 * Import action types here
 */
import { LIST_NEARBY_STATIONS, SET_USER_INFO, LIST_USERS_SESSIONS } from './action-types'



/**
 * Module internal initial state
 */
const initialState = {
    nearbyStations: [],
    userSessions: [],
    stationsTotalCount: null,
    userInfo: null,
};  


/**
 * Checks dispatched actions and updates state accordingly
 * @param {Object} state 
 * @param {Object} action 
 * @returns {Function} 
 */

const reducer = (state = initialState, {type, payload = null}) => {
    switch (type) {
        case LIST_NEARBY_STATIONS:
            return listChargingStations(state, payload)
        case SET_USER_INFO: 
            return setUserInfo(state, payload)
        case LIST_USERS_SESSIONS: 
            return listUserSessions(state, payload)
        default:
            return state;
    }
} 

/**
 * Returns an updated version of the state based on the action
 * @param {Object} state 
 * @param {Object} payload 
 * @return {Object} state
 */
function listChargingStations(state, payload) {
    return {
      ...state, nearbyStations: payload.isInitial ? payload.data.dataList : [...new Set(state.nearbyStations.concat(payload.data.dataList))] , stationsTotalCount: payload.data.totalCount
    }
}

function listUserSessions(state, payload) {
     //  console.log(payload)
    return {
      ...state, userSessions: payload.dataList, sessionsTotalCount: payload.totalCount
    }
}

function setUserInfo(state, payload) {
    return {
        ...state, userInfo: payload
    }
}

export default reducer