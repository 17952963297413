import React from 'react'
import { notification } from 'antd';

class BaseComponent extends React.Component {

	/**
	 * 
	 * @param {string} type 'error' | 'success' 
	 * @param {[]} messages 
	 */
    openNotification = (type, messages) => {
		let locale = localStorage.getItem('currentLocale')
		let headline = locale === 'english' ? (type === "error" ? "Error occured" : "Successful operation") : 
		(type === "error" ? "هناك خطأ" : "عملية ناجحة") 
		messages.forEach(message => {
			notification[type]({
				message: headline,
				description: message,
				placement: locale === 'english' ? 'topRight' : 'topLeft'
			});
		});
	};

	getCurrentLocation(){
		if (navigator && navigator.geolocation) {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(pos => {
                    const coords = pos.coords;
                    resolve({
                        lat: coords.latitude,
                        lng: coords.longitude
					});
                }, () => {
					fetch('http://ip-api.com/json').then(res => res.json()).then(data => {
						resolve({
							lat: data.lat,
							lng: data.lon
						})
					}).catch(() => {
						resolve({
							lat: null,
							lng: null
						})
					})
				});
            });
        }
        return {lat: 0,lng: 0};
	}

	fileDownload(data, filename, mime, bom) {
		var blobData = (typeof bom !== 'undefined') ? [bom, data] : [data]
		var blob = new Blob(blobData, {type: mime || 'application/octet-stream'});
		if (typeof window.navigator.msSaveBlob !== 'undefined') {
			// IE workaround for "HTML7007: One or more blob URLs were 
			// revoked by closing the blob for which they were created. 
			// These URLs will no longer resolve as the data backing 
			// the URL has been freed."
			window.navigator.msSaveBlob(blob, filename);
		}
		else {
			var blobURL = window.URL.createObjectURL(blob);
			var tempLink = document.createElement('a');
			tempLink.style.display = 'none';
			tempLink.href = blobURL;
			tempLink.setAttribute('download', filename); 
			
			// Safari thinks _blank anchor are pop ups. We only want to set _blank
			// target if the browser does not support the HTML5 download attribute.
			// This allows you to download files in desktop safari if pop up blocking 
			// is enabled.
			if (typeof tempLink.download === 'undefined') {
				tempLink.setAttribute('target', '_blank');
			}
			
			document.body.appendChild(tempLink);
			tempLink.click();
			
			// Fixes "webkit blob resource error 1"
			setTimeout(function() {
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
			}, 0)
		}
	}

	isNumberKey = evt => {
		var char = String.fromCharCode(evt.which);
		let allowedKeys = [97, 98, 99, 100, 101, 102, 103, 104, 105, 8, 46, 37, 39, 96, 110];
		if(parseFloat(evt.target.value) > 999.9 && evt.which !== 8){
			evt.preventDefault()
		}
		if (/[0-9]/.test(char) || allowedKeys.includes(evt.keyCode)) {
		  return true;
		} else {
		  evt.preventDefault();
		}
	}
	
	requestNotificationsPermission(onGrant, onDeny) {
	//	console.log('Requesting permission...');
		Notification.requestPermission().then((permission) => {
		if (permission === 'granted') {
			onGrant()
		} else {
			onDeny()	
		}
		});
	}

	retry(fn) {
		return new Promise((resolve, reject) => {
		  fn()
			.then(resolve)
			.catch((error) => {
			  window.location.reload(true)
			});
		});
	}

	changeBodyOverFlowY=(value)=>{
		let body=document.querySelectorAll('body')[0]
		body.style.overflowY=value?value:'hidden'
	}

}

export default BaseComponent