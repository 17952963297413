/**
 * Import action types here
 */
import {
    LIST_ADMINS,
    ADD_ADMIN,
    DELETE_ADMIN,
    EDIT_ADMIN,
    LIST_ROLES,
    ADD_ROLE,
    DELETE_ROLE,
    LIST_MASTER,
    SET_MASTER_ACTIVE_TYPE,
    ADD_DATA,
    DELETE_DATA,
    UPDATE_LOCATION,
    LIST_STATIONS,
    LIST_CUSTOMERS,
    LIST_CARD_REQUESTS,
    LIST_COUNTRIES,
    DELETE_COUNTRY,
    LIST_GOVERNORATES,
    SET_ACTIVE_COUNTRY,
    ADD_GOVERNORATE,
    DELETE_GOVERNORATE,
    SET_CUSTOMER_DETAILS,
    SET_CARD_DETAILS,
    STATION_CU,
    UPDATE_CARD_STATUS,
    LIST_CONTACT_REQUESTS,
    UPDATE_CONTACT_STATUS,
    SET_DRAWER_ACTIVE_TAB,
    INSER_CHARGER_DETAILS,
    LIST_CHARGERS_DETAILS,
    APPEND_CONNECTORS_TO_CHARGER,
    LIST_CHARGING_SESSIONS,
    EDIT_CONNECTORS_TO_CHARGER,
    DELETE_CHARGERS_FROM_LIST,
    EDIT_CHARGER_DETAILS,
    DELETE_CHARGER_STATION_LIST,
    SET_CHARGER_DETAILS_LIST,
    RESET_CHARGER_DETAILS
} from "./action-types";

/**
 * Module internal initial state
 */
const initialState = {
    adminsList: [],
    dataList: [],
    rolesList: [],
    stationsList: [],
    customersList: [],
    cardRequestsList: [],
    countriesList: [],
    governoratesList: [],
    contactRequestsList: [],
    chargingSessionsList: [],
    rolesTotalCount: null,
    totalCount: null,
    masterTotalCount: null,
    activeMasterType: null,
    countriesTotalCount: null,
    currentLocation: null,
    stationsTotalCount: null,
    customersTotalCount: null,
    cardRequestsTotalCount: null,
    contactRequestsTotalCount: null,
    governoratesTotalCount: null,
    chargingSessionsTotalCount: null,
    activeCountry: null,
    customerDetails: null,
    chargerDetails: [],
    chargersstationList: null,
    cardDetails: null,
    drawerActiveTab: '1',
};


/**
 * Checks dispatched actions and updates state accordingly
 * @param {Object} state 
 * @param {Object} action 
 * @returns {Function} 
 */

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case LIST_ADMINS:
            return listAdmins(state, payload)
        case ADD_ADMIN:
            return addAdmin(state, payload)
        case DELETE_ADMIN:
            return deleteAdmin(state, payload)
        case EDIT_ADMIN:
            return editAdmin(state, payload)
        case LIST_ROLES:
            return listRoles(state, payload)
        case ADD_ROLE:
            return addRole(state, payload)
        case DELETE_ROLE:
            return deleteRole(state, payload)
        case LIST_MASTER:
            return listMaster(state, payload)
        case SET_MASTER_ACTIVE_TYPE:
            return setMasterActiveType(state, payload)
        case ADD_DATA:
            return addData(state, payload)
        case DELETE_DATA:
            return deleteData(state, payload)
        case UPDATE_LOCATION:
            return updateLocation(state, payload)
        case LIST_STATIONS:
            return listStations(state, payload)
        case STATION_CU:
            return stationCU(state, payload)
        case LIST_CUSTOMERS:
            return listCustomers(state, payload)
        case LIST_CARD_REQUESTS:
            return listCardRequests(state, payload)
        case LIST_COUNTRIES:
            return listCountries(state, payload)
        case DELETE_COUNTRY:
            return deleteCountry(state, payload)
        case LIST_GOVERNORATES:
            return listGovernorates(state, payload)
        case SET_ACTIVE_COUNTRY:
            return setActiveCountry(state, payload)
        case ADD_GOVERNORATE:
            return addGovernorate(state, payload)
        case DELETE_GOVERNORATE:
            return deleteGovernorate(state, payload)
        case SET_CUSTOMER_DETAILS:
            return setCustomerDetails(state, payload)
        case SET_CARD_DETAILS:
            return setCardDetails(state, payload)
        case UPDATE_CARD_STATUS:
            return updateCardStatus(state, payload)
        case LIST_CONTACT_REQUESTS:
            return listContactUsRequests(state, payload)
        case LIST_CHARGERS_DETAILS:
            return listChargersStation(state, payload)
        case UPDATE_CONTACT_STATUS:
            return updateContactStatus(state, payload)
        case SET_DRAWER_ACTIVE_TAB:
            return setDrawerActiveTab(state, payload)
        case INSER_CHARGER_DETAILS:
            return setChargerDetailes(state, payload)
        case APPEND_CONNECTORS_TO_CHARGER:
            return appendConnectorsList(state, payload)
        case LIST_CHARGING_SESSIONS:
            return listChargingSessions(state, payload)
        case EDIT_CONNECTORS_TO_CHARGER:
            return editConnectorsList(state, payload)
        case DELETE_CHARGERS_FROM_LIST:
            return deleteChargersFromList(state, payload)
        case EDIT_CHARGER_DETAILS:
            return editChargerDetails(state, payload)
        case DELETE_CHARGER_STATION_LIST:
            return deleteChargerStationList(state, payload)
        case SET_CHARGER_DETAILS_LIST:
            return setChargerDetailsList(state, payload)
        case RESET_CHARGER_DETAILS:
            return { ...state, chargerDetails: [] }
        default:
            return state;
    }
}

/**
 * Returns an updated version of the state based on the action
 * @param {Object} state 
 * @param {Object} payload 
 * @return {Object} state
 */
function listAdmins(state, payload) {
    return {
        ...state, adminsList: payload.data, totalCount: payload.total
    }
}

function addAdmin(state, payload) {
    return {
        ...state, adminsList: [payload, ...state.adminsList], totalCount: state.totalCount + 1
    }
}

function deleteAdmin(state, payload) {
    return {
        ...state,
        adminsList: state.adminsList.filter(user => user.adminID !== payload),
        totalCount: state.totalCount - 1
    }
}

function editAdmin(state, payload) {
    let index = state.adminsList.findIndex(admin => admin.adminID === payload.adminID)
    let updatedList = state.adminsList
    updatedList[index].name = payload.firstName
    updatedList[index].email = payload.email
    updatedList[index].adminID = payload.adminID

    return {
        ...state, adminsList: updatedList
    }
}

function listRoles(state, payload) {
    return {
        ...state, rolesList: payload.data.dataList, rolesTotalCount: payload.data.totalCount
    }
}

function addRole(state, payload) {
    return {
        ...state, rolesList: [payload, ...state.rolesList], rolesTotalCount: state.rolesTotalCount + 1
    }
}

function deleteRole(state, payload) {
    return {
        ...state,
        rolesList: state.rolesList.filter(role => role.id !== payload),
        rolesTotalCount: state.rolesTotalCount - 1
    }
}

function listMaster(state, payload) {
    return {
        ...state, dataList: payload.data, masterTotalCount: payload.total
    }
}

function setMasterActiveType(state, payload) {
    return {
        ...state, activeMasterType: payload
    }
}

function addData(state, payload) {
    return {
        ...state, dataList: [payload, ...state.dataList], masterTotalCount: state.masterTotalCount + 1
    }
}

function deleteData(state, payload) {
    return {
        ...state,
        dataList: state.dataList.filter(data => data.id !== payload),
        masterTotalCount: state.masterTotalCount - 1
    }
}

function updateLocation(state, payload) {
    return {
        ...state, currentLocation: payload
    }
}

function listStations(state, payload) {
    return {
        ...state, stationsList: payload.dataList, stationsTotalCount: payload.totalCount
    }
}

function stationCU(state, payload) {
    let stationIndex = state.stationsList.findIndex(station => station.chargingPlaceID === payload.chargingPlaceID)
    let stations = state.stationsList
    if (stationIndex > -1) {
        for (const key in payload) {
            stations[stationIndex][key] = payload[key]
        }
        return {
            ...state, stationsList: stations
        }
    }
    else {
        return {
            ...state, stationsList: [payload, ...state.stationsList], stationsTotalCount: state.stationsTotalCount + 1
        }
    }
}

function listCustomers(state, payload) {
    return {
        ...state, customersList: payload.dataList, customersTotalCount: payload.totalCount
    }
}

function listCardRequests(state, payload) {
    return {
        ...state, cardRequestsList: payload.dataList, cardRequestsTotalCount: payload.totalCount
    }
}

function listCountries(state, payload) {
    return {
        ...state, countriesList: payload.data, countriesTotalCount: payload.total
    }
}

function deleteCountry(state, payload) {
    return {
        ...state,
        countriesList: state.countriesList.filter(data => data.id !== payload),
        countriesTotalCount: state.countriesTotalCount - 1
    }
}

function listGovernorates(state, payload) {
    return {
        ...state, governoratesList: payload.data, governoratesTotalCount: payload.total
    }
}

function setActiveCountry(state, payload) {
    return {
        ...state, activeCountry: payload
    }
}

function addGovernorate(state, payload) {
    let index = state.governoratesList.findIndex(gov => gov.id === payload.id)
    let govsList = state.governoratesList
    if (index === -1) {
        return {
            ...state, governoratesList: [payload, ...state.governoratesList], governoratesTotalCount: state.governoratesTotalCount + 1
        }
    }
    else {
        govsList[index].name = payload.name
        govsList[index].isActive = payload.isActive

        return {
            ...state, governoratesList: govsList
        }
    }
}

function deleteGovernorate(state, payload) {
    return {
        ...state,
        governoratesList: state.governoratesList.filter(data => data.id !== payload),
        governoratesTotalCount: state.governoratesTotalCount - 1
    }
}

function setCustomerDetails(state, payload) {
    return {
        ...state,
        customerDetails: payload,
    }
}

function setCardDetails(state, payload) {
    return {
        ...state,
        cardDetails: payload,
    }
}

function updateCardStatus(state, payload) {
    let index = state.cardRequestsList.findIndex(request => request.requestID === payload.id)
    let requestsList = state.cardRequestsList
    if (index > -1) {
        requestsList[index].cardStatus = payload.status
    }
    return {
        ...state, cardRequestsList: requestsList
    }
}

function listContactUsRequests(state, payload) {
    return {
        ...state, contactRequestsList: payload.dataList, contactRequestsTotalCount: payload.totalCount
    }
}

function updateContactStatus(state, payload) {
    let index = state.contactRequestsList.findIndex(request => request.requestID === payload.id)
    let requestsList = state.contactRequestsList
    if (index > -1) {
        requestsList[index].status = payload.status
    }
    return {
        ...state, contactRequestsList: requestsList
    }
}

function setDrawerActiveTab(state, payload) {
    return {
        ...state, drawerActiveTab: payload
    }
}

function setChargerDetailes(state, payload) {
    let chargerDetails = state.chargerDetails
    let length = chargerDetails.length
    //  console.log('length',length)
    if (payload !== null) {
        if (length === 0) {
            payload.id = 0
            payload.chargerID = 0
        }

        else {
            payload.id = chargerDetails[length - 1].id + 1
            payload.chargerID = chargerDetails[length - 1].id + 1

        }
    }

    return {
        ...state,
        chargerDetails: [...state.chargerDetails, payload],
    }
}

function appendConnectorsList(state, payload) {
    let chargerDetails = state.chargerDetails
    Object.assign(chargerDetails[state.chargerDetails.length - 1], payload)
    return {
        ...state,
        chargerDetails
    }
}

function editConnectorsList(state, payload) {
    let chargerDetails = state.chargerDetails
    //  console.log('edit payload',payload)

    let index = chargerDetails.findIndex((item) => item.id === payload.chargerInfo.id)
    //  console.log('indx  sds',index)
    if (index !== -1) {
        chargerDetails[index].connectors = payload.body.connectors
        chargerDetails[index].comment = payload.body.comment

    }
    //  console.log(payload)
    return {
        ...state,
        chargerDetails
    }
}

function listChargersStation(state, payload) {
    return {
        ...state, chargersstationList: payload,
    }
}

function listChargingSessions(state, payload) {
    return {
        ...state, chargingSessionsList: payload.dataList, chargingSessionsTotalCount: payload.totalCount
    }
}



function deleteChargersFromList(state, payload) {
    //  console.log('delete payload',payload)
    let chargerDetails
    if (payload === null) {
        chargerDetails = []
    }
    else {
        if (payload.modelState === undefined) {
            chargerDetails = state.chargerDetails
            let index = chargerDetails.findIndex((item) => item.chargerID === payload.chargerID)
            chargerDetails[index].modelState = 3
        }
        else {
            chargerDetails = state.chargerDetails.filter((item) => item.chargerID !== payload.chargerID)

        }
    }
    return {
        ...state,
        chargerDetails
    }
}

function editChargerDetails(state, payload) {
    let chargerDetails = state.chargerDetails
    let index = chargerDetails.findIndex((item) => item.chargerID === payload.chargerID)
    chargerDetails[index] = payload
    return {
        ...state,
        chargerDetails
    }
}


function deleteChargerStationList(state, payload) {
    let chargersstationList = null
    return {
        ...state,
        chargersstationList
    }
}

function setChargerDetailsList(state, payload) {
    //  console.log(payload)
    let chargerDetails = payload
    return {
        ...state,
        chargerDetails
    }
}


export default reducer